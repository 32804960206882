import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import ContructionTeamSection from "../components/static-sections/ContructionTeamSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import HeroMiddleSection from "../components/dynamic-sections/HeroMiddleSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import AppStoreIcon from "../../assets/appstore-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ConstructionTeamPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/construction-crew/",
          name: "Construction Crew | Norhart",
          image: `${config.siteMetadata.siteUrl}/construction-crew/norhart-construction-team-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Construction Team | Norhart"
      description="Norhart builds and rents exceptional luxury apartments in Forest Lake, Blaine, Circle Pines, Lexington, and Oakdale, Minnesota. We love creating a better way for people to live!"
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/construction-crew/norhart-construction-team-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/construction-crew/norhart-construction-team-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Construction Crew"
        subtitle="Join Our Crew Today"
        imageTitle="Norhart Construction Team"
        image="/construction-crew/norhart-construction-team-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="LEAN Construction" tagLine="Cost-to-Value Distruption" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <ContructionTeamSection colorPalette={colorPalette} />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Construction Team"
        title="Norhart Crews Are On The Move!"
        formatSubTitle={() => (
          <>
            When cruising around the Minneapolis metro area, be on the lookout for our buildings, crews, and equipment!
            We are growing and expanding fast! We have our sights set on more metro areas in the US. #StartupGrowth
            #HockeyStickGrowth
          </>
        )}
        image="/construction-crew/norhart-construction-crews.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Methodology"
        title="Lean Is The Way!"
        subTitle="At Norhart, our processes help us decrease time, effort, and waste. It also ensures our projects are done at a lower cost and on time while maintaining exceptional build quality."
        image="/construction-crew/norhart-process-lean-is-the-way.gif"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroMiddleSection
        imageTitle="Norhart Lean Construction"
        image="/construction-crew/norhart-process-lean-construction.png"
        colorPalette={colorPalette}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Now Hiring"
        title="Yes! Be One Of Us!"
        subTitle="We are spreading the word in the Minneapolis/St. Paul Metro area that Norhart is hiring individuals who want to change the world one apartment at a time! Should you apply? #HellYeah"
        image="/careers/norhart-careers-construction-site-sign.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        buttonName="Search Norhart Jobs"
        buttonUrl="/careers/"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ConstructionTeamPage
